import React, { useState } from "react";
import clsx from "clsx";
import { FaTimes } from "react-icons/fa";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";
import logoImage from "../../Assets/xonick_pay_logo.svg";
import { LinkComponent } from "XonickPay-Components-Lib";
import { NavItemProps } from "../../Models/types";

const HeaderComponent: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const navItems: NavItemProps[] = [
    {
      navItem: "Home",
      link: "/",
    },
    {
      navItem: "Mission",
      link: "/about",
    },
    {
      navItem: "How it works",
      link: "/work",
    },
    {
      navItem: "FAQs",
      link: "/faq",
    },
    {
      navItem: "Support",
      link: "/support",
    },
    {
      navItem: "Announcement",
      link: "/announcement",
    },
    {
      navItem: "Docs",
      link: "/docs",
    },
  ];

  return (
    <header className="flex bg-blue-50 px-6 py-4 top-0 right-0 w-full z-50 sticky">
      <div className="w-full mx-auto flex flex-wrap justify-between items-center">
        <LinkComponent to="#">
          <img src={logoImage} alt="logo" className="sm:w-36 w-28" />
        </LinkComponent>

        <div
          id="collapseMenu"
          className={`${
            menuOpen ? "block" : "hidden"
          } lg:flex lg:items-center lg:space-x-5 relative`}
        >
          <button
            id="toggleClose"
            className="lg:hidden -top-10 -right-28 sm:right-36 z-50 rounded-full bg-white p-3 absolute"
            onClick={handleToggle}
          >
            <FaTimes className="w-4 h-4 fill-black" />
          </button>

          <ul className="flex flex-col lg:flex-row lg:items-center gap-x-5 space-y-3 lg:space-y-0 fixed lg:relative bg-white lg:bg-transparent lg:w-full sm:w-5/12 w-full p-6 lg:p-0 top-0 left-0 lg:top-auto lg:left-auto z-40 h-screen lg:h-auto">
            {navItems.map((item: any, index) => (
              <li
                key={index}
                className="lg:border-none border-b border-gray-300 py-3 px-3 lg:py-0 lg:px-0"
              >
                <LinkComponent
                  to={item.link}
                  className={clsx(
                    "flex items-center w-full p-2 text-gray-900 font-medium text-lg",
                    location.pathname === item.link
                      ? "underline decoration-orange-400 text-orange-400 decoration-1 underline-offset-8 font-bold"
                      : ""
                  )}
                >
                  {item.navItem}
                </LinkComponent>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex space-x-3 items-center">
          <LinkComponent
            to="/login"
            className="py-2 px-4 border border-spacing-1 rounded-md bg-gradient-to-r from-orange-500 to-amber-500 font-medium text-white"
          >
            Login
          </LinkComponent>
          <button id="toggleOpen" className="lg:hidden" onClick={handleToggle}>
            <HiMiniBars3BottomLeft className="w-7 h-7 fill-black" />
          </button>
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
