import React, { createContext, useContext, useEffect, useState } from "react";
import { SignalRService } from "XonickPay-Services/dist/Services/SignalR/SignalRService";
import { WalletSignalRService } from "XonickPay-Services/dist/Services/SignalR/WalletSignalRService";
import { PopupComponent } from "XonickPay-Components-Lib"; // Import your Popup component

interface ISignalRContext {
  signalRService: SignalRService;
  walletSignalRService: WalletSignalRService;
  completeOrder: () => void;
}

const SignalRContext = createContext<ISignalRContext | undefined>(undefined);

export const SignalRProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<string | null>(
    sessionStorage.getItem("UID")
  );
  const [isOrderCompleted, setIsOrderCompleted] = useState<boolean>(false);
  const [connectionError, setConnectionError] = useState<string | null>(null);
  const [showReconnectPopup, setShowReconnectPopup] = useState<boolean>(false); // State for reconnecting popup
  const [showErrorPopup, setShowErrorPopup] = useState<boolean>(false); // State for error popup

  const hubUrl = `${process.env.REACT_APP_HUB_URL}?userId=${userId}`;
  const walletHubUrl = `${process.env.REACT_APP_WALLET_HUB_URL}?userId=${userId}`;

  const signalRService = SignalRService.getInstance();
  const walletSignalRService = WalletSignalRService.getInstance();
  let token = sessionStorage.getItem("Token");

  useEffect(() => {
    const handleStorageChange = () => {
      setUserId(sessionStorage.getItem("UID"));
    };

    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const connectSignalR = async () => {
    while (true) {
      try {
        await signalRService.startConnection(hubUrl, token);

        setConnectionError(null); // Reset error on successful connection
        setShowReconnectPopup(false); // Hide reconnecting popup

        break; // Exit loop if connection is successful
      } catch (err) {
        console.error("Error while connecting to order hub:", err);
        setConnectionError("Error connecting to order hub. Retrying...");

        // Show reconnecting popup
        setShowReconnectPopup(true);

        // Refresh token and try reconnecting
        token = sessionStorage.getItem("Token");
        if (token) {
          await connectSignalR();
        }

        await new Promise((resolve) => setTimeout(resolve, 30000)); // Wait before retrying

        // If connection fails after several attempts, show error popup
        setShowReconnectPopup(false);
        setShowErrorPopup(true);
        break; // Exit loop if we want to show the error
      }
    }
  };

  const connectWalletService = async () => {
    try {
      await walletSignalRService.startConnection(walletHubUrl, token);
    } catch (err) {
      console.error("Error while connecting to wallet hub:", err);
      setConnectionError("Error connecting to wallet hub.");
    }
  };

  useEffect(() => {
    if (userId) {
      connectSignalR();
    }
  }, [hubUrl, userId, isOrderCompleted]);

  useEffect(() => {
    if (userId) {
      connectWalletService();
    }
  }, [walletHubUrl, userId]);

  const completeOrder = () => {
    setIsOrderCompleted(true);
    // signalRService.disconnect(); // Explicitly disconnect if needed
  };

  return (
    <SignalRContext.Provider
      value={{ signalRService, walletSignalRService, completeOrder }}
    >
      {showReconnectPopup && (
        <PopupComponent
          isOpen={showReconnectPopup}
          onClose={() => setShowReconnectPopup(false)}
          popupClassName="bg-gray-100 p-4 rounded-lg shadow-lg"
          acceptText="Reconnect"
          acceptClassName="cursor-pointer bg-primary-500 hover:bg-primary-700 text-white font-semibold py-2 px-4 rounded-md"
          onAccept={() => {
            signalRService.startConnection(
              `${process.env.REACT_APP_HUB_URL}?userId=${userId}`,
              token!
            );
            setShowReconnectPopup(false);
          }}
        >
          <p>We are trying to establish the connection to the server...</p>
        </PopupComponent>
      )}
      {showErrorPopup && (
        <PopupComponent
          isOpen={showErrorPopup}
          onClose={() => setShowErrorPopup(false)}
          popupClassName="bg-red-100 p-4 rounded-lg shadow-lg relative"
        >
          <p>Something went wrong. Please refresh the page again.</p>
        </PopupComponent>
      )}
      {children}
    </SignalRContext.Provider>
  );
};

export const useSignalRService = (): SignalRService => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useSignalRService must be used within a SignalRProvider");
  }
  return context.signalRService;
};

export const useWalletSignalRService = (): WalletSignalRService => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error(
      "useWalletSignalRService must be used within a SignalRProvider"
    );
  }
  return context.walletSignalRService;
};

export const useCompleteOrder = (): (() => void) => {
  const context = useContext(SignalRContext);
  if (context === undefined) {
    throw new Error("useCompleteOrder must be used within a SignalRProvider");
  }
  return context.completeOrder;
};
