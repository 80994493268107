import React from "react";
import * as Yup from "yup";
import { Converter } from "XonickPay-Components-Lib";
import BlockChainBg from "../../Assets/new-bg-custom.svg";

const ConverterHome = () => {
  const validationSchema = Yup.object().shape({
    usdt: Yup.string()
      .required("usdt is required")
      .notOneOf(["0"], "Please enter the amount greater then 0"),
    inr: Yup.string()
      .required("inr is required")
      .notOneOf(["0"], "Please enter the amount greater then 0"),
  });

  const firstInputsSign = "USDT";
  const secondInputsSign = "INR";

  // Function to pad the string
  const padString = (str: any, length: any) => {
    while (str.length < length) {
      str += "\u00A0\u00A0\u00A0\u00A0";
    }
    return str;
  };

  const paddedSecondInputSign = padString(
    secondInputsSign,
    firstInputsSign.length
  );

  return (
    <div
      className=" flex flex-col items-center justify-center p-4 text-white  bg-fixed overflow-y-auto rounded-lg shadow-lg bg-center bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${BlockChainBg})` }}
    >
      <div className="flex flex-col items-center justify-center text-center min-h-96 w-full">
        <h2 className="text-2xl sm:text-xl md:text-3xl 2xl:text-3xl top-0 sm:mb-3 mb-2">
          How Much Will You Get?
        </h2>
        <p className="text-xl sm:text-4xl md:text-5xl 2xl:6xl font-bold sm:mb-2 mb-1">
          Check it out with our&nbsp;
          <span className="text-orange-400">USDT/INR Converter</span>
        </p>
        <Converter
          firstInputSign={firstInputsSign}
          secondInputSign={paddedSecondInputSign}
          initialValues={{ usdt: 1, inr: 90.0 }}
          validationSchema={validationSchema}
          conversionRate={90}
          firstInputName={"usdt"}
          secondInputName={"inr"}
          mainContainerDivClassName="flex md:flex-row flex-col md:space-y-0 space-y-4 space-x-0 items-center md:space-x-8"
          secondInputClassName="bg-transparent border border-1 p-3 m-2 rounded"
          firstInputClassName="bg-transparent border border-1 p-3 m-2 rounded"
          InputSignClassName="bg-white text-black font-semibold absolute inset-y-0 end-2 top-[0.8rem] p-2 py-6 rounded-tr-[0.25rem] rounded-br-[0.25rem] h-4 flex items-center pe-2 pointer-events-none"
        />
      </div>
    </div>
  );
};

export default ConverterHome;
