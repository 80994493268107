import React, { useEffect, useState } from "react";
import MainLayout from "../Layouts/MainLayout";
import banner from "../Assets/xnpBanner.svg";
import rightImg from "../Assets/token-right-image.svg";
import launchImg from "../Assets/launch-image.svg";

const Announcement: React.FC = () => {
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setRotation(window.scrollY * 0.2);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const cardData = [
    {
      percentage: "42%",
      title: "Public Sale",
      description: "Increase token circulation and raise capital.",
    },
    {
      percentage: "12%",
      title: "Team and Advisors",
      description:
        "Subject to a 1-year cliff and 4-year vesting to ensure long-term alignment with project goals.",
    },
    {
      percentage: "9%",
      title: "Staking Rewards",
      description: "To incentivize network participation and security.",
    },
    {
      percentage: "9%",
      title: "Reserve Fund",
      description: "For unforeseen expenses and future developmental needs.",
    },
    {
      percentage: "9%",
      title: "Community and Ecosystem",
      description: " For community initiatives and growth (eg, airdrop)",
    },
    {
      percentage: "9%",
      title: "Liquidity Provision",
      description: " To ensure market stability.",
    },
    {
      percentage: "1%",
      title: "Charity",
      description: "Dedicated to charitable causes.",
    },
    {
      percentage: "3%",
      title: "Promoters",
      description: "",
    },
  ];

  const tokenData = [
    {
      title: "Transaction Fees:",
      description:
        "Holders of the XNP token will pay platform fees at a reduced rate in the future.",
      borderColor: "border-orange-500",
      textColor: "text-orange-500",
    },
    {
      title: "Governance:",
      description:
        "Token holders can participate in governance decisions, influencing the development and operation of the platform.",
      borderColor: "border-blue-500",
      textColor: "text-blue-500",
    },
    {
      title: "Referral Benefit:",
      description:
        "Earn XNP tokens by sharing the fees paid by your referrals who use Xonick to convert fiat to crypto and vice versa.",
      borderColor: "border-orange-500",
      textColor: "text-orange-500",
    },
  ];

  const steps = [
    {
      id: 1,
      title: "Create an Account",
      description: "Sign up on the Xonick Pay platform.",
    },
    {
      id: 2,
      title: "KYC Verification",
      description: "Complete the necessary Know Your Customer (KYC) process.",
    },
    {
      id: 3,
      title: "Connect Wallet",
      description: "We help you link your ERC-20 compatible wallet.",
    },
    {
      id: 4,
      title: "Purchase Method",
      description:
        "Buy XNP using Ethereum (ETH), INR, or USDT as per your convience.",
    },
  ];

  const events = [
    {
      id: 1,
      title: "Q1 2025",
      description:
        "Completion of Token development and integration into the Tron network.",
      position: "left",
    },
    {
      id: 2,
      title: "Q2 2025",
      description:
        "Integrating XNP into the platform and reduced transaction fees for XNP holders.",
      position: "right",
    },
    {
      id: 3,
      title: "Q3 2025",
      description: " Expansion of XNP token onto other exchanges.",
      position: "left",
    },
    {
      id: 4,
      title: "Q4 2025",
      description:
        "Introducing staking mechanisms, liquidity pools and reward structures onto Xonick Pay.",
      position: "right",
    },
  ];

  const launchDetails = [
    { label: "Launch Date:", value: "7th December" },
    { label: "Network:", value: "Ethereum Mainnet" },
    { label: "Initial Price:", value: "$0.10 per XNP" },
    { label: "KYC Required:", value: "Yes, for all participants" },
  ];

  return (
    <MainLayout>
      <div className="min-h-screen">
        {/* hero banner */}
        <div className="relative h-96 flex items-center justify-center overflow-hidden">
          <div
            className="absolute inset-0 bg-cover bg-center"
            style={{ backgroundImage: `url(${banner})` }}
          />
          <div className="absolute inset-0 overflow-hidden">
            <div
              className="absolute top-1/3 right-1/4 w-60 h-60 bg-gradient-to-r from-blue-500 to-cyan-500 rounded-full blur-xl opacity-30 animate-pulse"
              style={{ transform: `rotate(-${rotation}deg) translateZ(150px)` }}
            />
            <div
              className="absolute bottom-1/4 left-1/3 w-48 h-48 bg-gradient-to-r from-indigo-500 to-purple-500 rounded-full blur-xl opacity-30 animate-pulse"
              style={{
                transform: `rotate(${rotation * 0.5}deg) translateZ(120px)`,
              }}
            />
          </div>

          <div className="relative z-10 text-center px-4 mx-auto">
            <div
              className="transform-style-3d"
              style={{ transform: `translateZ(50px)` }}
            >
              <h1 className="lg:text-6xl text-4xl font-bold text-white animate-pulse shadow-2xl">
                Xonick Network Protocol&nbsp;
                <span className="text-orange-500">(XNP)</span>
              </h1>
              <p className="lg:text-3xl text-lg mb-8 text-orange-500 text-shadow-lg">
                XNP fuels utility and decision-making.
              </p>
            </div>
          </div>
        </div>

        {/* token insights */}
        <div className="relative bg-white flex items-center gap-8">
          <div className="flex lg:flex-col flex-row items-start py-12 px-4 lg:px-8 md:w-1/2">
            <div className="lg:ml-8 md:w-full lg:w-[43rem] xl:w-[57rem] 2xl:w-[70rem] 3xl:w-[86rem] 4xl:w-[110rem]">
              <h2 className="font-bold text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl text-gray-900">
                XNP Token Insights
              </h2>
              <div className="flex flex-wrap gap-8 py-8">
                {tokenData.map((card, index) => (
                  <div
                    key={index}
                    className={`border-2 ${card.borderColor} rounded-md p-4 md:max-w-sm`}
                  >
                    <h3
                      className={`text-xl font-semibold border-l-4 pl-2 ${card.borderColor}`}
                    >
                      {card.title}
                    </h3>
                    <p className={`mt-2 ${card.textColor}`}>
                      {card.description}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="md:w-1/2 hidden md:block my-auto">
            <img
              className="md:h-72 xl:h-80 2xl:h-96 mx-auto"
              src={rightImg}
              alt="Card image"
            />
          </div>
        </div>

        {/* Tokenomics */}
        <div className="pb-20 pt-10 px-4 lg:px-20">
          <h2 className="text-4xl font-bold text-center mb-16">Tokenomics</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 justify-items-center">
            {cardData.map((card, index) => (
              <div
                key={index}
                className="relative bg-blue-200 text-center p-6 rounded-lg w-64 shadow-md"
              >
                <div className="absolute -top-4 -left-4 bg-blue-700 text-white text-sm font-semibold w-12 h-12 rounded-full flex items-center justify-center">
                  {card.percentage}
                </div>
                <h3 className="text-lg font-semibold">{card.title}</h3>
                <p className="mt-2 text-sm text-gray-700">{card.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* launch Details */}
        <div className="pb-20 pt-10 px-4 lg:px-20 bg-white">
          <h2 className="text-4xl font-bold text-center mb-16">
            Launch Details
          </h2>
          <div className="relative flex flex-col items-start bg-gradient-to-r from-yellow-400 to-orange-400 p-6 rounded-lg shadow-md w-full max-w-lg mx-auto">
            <div className="absolute -top-24 -left-16 flex items-center justify-center">
              <img src={launchImg} alt="launch image" className="h-32 w-32" />
            </div>

            <div className="grid grid-cols-2 gap-6">
              <ul className="space-y-4 text-white text-lg font-medium mt-6">
                {launchDetails.map((detail, index) => (
                  <li key={index}>{detail.label}</li>
                ))}
              </ul>
              <ul className="space-y-4 text-black text-lg font-medium mt-6">
                {launchDetails.map((detail, index) => (
                  <li key={index}>{detail.value}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        {/* How To Participate */}
        <div className="pb-20 pt-10 px-4 lg:px-20">
          <h2 className="text-4xl font-bold text-center mb-16">
            How To Participate
          </h2>
          <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-8 p-4">
            {steps.map((step) => (
              <div key={step.id} className="flex items-center gap-4">
                <div className="flex items-center justify-center w-16 h-16 bg-blue-200 rounded-full">
                  <span className="text-blue-500 text-3xl font-bold">
                    {step.id}
                  </span>
                </div>
                <div>
                  <h2 className="text-blue-800 text-lg font-bold">
                    {step.title}
                  </h2>
                  <p className="text-gray-700 text-sm">{step.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* roadmap */}
        <div className="pb-20 pt-10 px-4 lg:px-20 bg-white">
          <h2 className="text-4xl font-bold text-center mb-16">Roadmap</h2>
          <div className="flex flex-col grid-cols-9 p-2 mx-auto md:grid">
            {events.map((event, index) => (
              <div
                key={event.id}
                className={`flex md:contents ${
                  event.position === "right" ? "" : "flex-row-reverse"
                }`}
              >
                {event.position === "left" && (
                  <div className="relative p-4 my-6 text-gray-800 bg-sky-50 rounded-xl col-start-1 col-end-5 mr-auto md:mr-0 md:ml-auto">
                    <h3 className="text-lg font-semibold lg:text-xl">
                      {event.title}
                    </h3>
                    <p className="mt-2 leading-6">{event.description}</p>
                  </div>
                )}
                <div className="relative col-start-5 col-end-6 mr-7 md:mx-auto">
                  <div className="flex items-center justify-center w-6 h-full">
                    <div className="w-1 h-full bg-indigo-300" />
                  </div>
                  <div className="absolute w-6 h-6 -mt-3 bg-sky-50 border-4 border-indigo-400 rounded-full top-1/2" />
                </div>
                {event.position === "right" && (
                  <div className="relative p-4 my-6 text-gray-800 bg-sky-50 rounded-xl col-start-6 col-end-10 mr-auto">
                    <h3 className="text-lg font-semibold lg:text-xl">
                      {event.title}
                    </h3>
                    <p className="mt-2 leading-6">{event.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Announcement;
