import React from "react";
import MainLayout from "../Layouts/MainLayout";

const Docs = () => {
  return (
    <MainLayout>
      <div className="min-h-screen py-10 px-6 lg:px-20">
        <div className="h-4/5 p-5 overflow-auto max-w-4xl mx-auto pb-10">
          <h2 className="text-3xl font-bold text-center mb-10">XNP Token</h2>
          <div className="mt-2 px-6">
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>Token Name: Xonick Network Protocol (XNP)</li>
              <li>Token Type: ERC-20</li>
              <li>Total Supply: 100,000,000,000 XNP</li>
              <li>Decimals: 18</li>
            </ul>
          </div>
          {/* Token Overview */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Token Overview</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                Transaction Fees: Holders of the XNP token will pay platform
                fees at a reduced rate in the future.
              </li>
              <li>
                Governance: Token holders can participate in governance
                decisions, influencing the development and operation of the
                platform.
              </li>
              <li>
                Referral Benefit: Earn XNP tokens by sharing the fees paid by
                your referrals who use Xonick to convert fiat to crypto and vice
                versa.
              </li>
            </ul>
          </div>
          {/* Tokenomics */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Tokenomics</h2>
            <span className="text-lg">
              The distribution and economic strategy of XNP is as follows -
            </span>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                Public Sale: 42% (42,000,000,000 XNP) - To increase token
                circulation and raise capital.
              </li>
              <li>
                Team and Advisors: 18% (18,000,000,000 XNP) - Subject to a
                1-year cliff and 4-year vesting to ensure long-term alignment
                with project goals.
              </li>
              <li>
                Staking Rewards: 9% (9,000,000,000 XNP) - To incentivize network
                participation and security.
              </li>
              <li>
                Reserve Fund: 9% (9,000,000,000 XNP) - For unforeseen expenses
                and future developmental needs.
              </li>
              <li>
                Community and Ecosystem: 9% (9,000,000,000 XNP) - For community
                initiatives and growth (eg, airdrop)
              </li>
              <li>
                Liquidity Provision: 9% (9,000,000,000 XNP) - To ensure market
                stability.
              </li>
              <li>
                Charity: 1% (1,000,000,000 XNP) - Dedicated to charitable
                causes.
              </li>
              <li>Promoters: 3% (3,000,000,000 XNP)</li>
            </ul>
          </div>
          {/* Launch Details */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Launch Details</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>Launch Date: 7th December</li>
              <li>Network: Ethereum Mainnet</li>
              <li>Initial Price: $0.10 per XNP</li>
              <li>KYC Required: Yes, for all participants</li>
            </ul>
          </div>
          {/* How to Participate */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">How to Participate</h2>
            <span className="text-lg">
              Follow these steps to acquire XNP during the pre-sale:
            </span>
            <ul className="text-gray-700 text-lg list-decimal pl-11">
              <li>
                <span className="font-semibold text-black">
                  Create an Account:
                </span>
                &nbsp; Sign up on the Xonick Pay platform.
              </li>
              <li>
                <span className="font-semibold text-black">
                  KYC Verification:
                </span>
                &nbsp; complete the necessary Know Your Customer (KYC) process.
              </li>
              <li>
                <span className="font-semibold text-black">
                  Connect Wallet:
                </span>
                &nbsp; We help you link your ERC-20 compatible wallet.
              </li>
              <li>
                <span className="font-semibold text-black">
                  Purchase Method:
                </span>
                &nbsp; Buy XNP using Ethereum (ETH), INR, or USDT as per your
                convenience.
              </li>
            </ul>
          </div>
          {/* Roadmap */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Roadmap</h2>
            <span className="text-lg">The roadmap of XNP includes,</span>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                Q1 2025: Completion of Token development and integration into
                the Tron network.
              </li>
              <li>
                Q2 2025: Integrating XNP into the platform and reduced
                transaction fees for XNP holders.
              </li>
              <li>Q3 2025: Expansion of XNP token onto other exchanges.</li>
              <li>
                Q4 2025: Introducing staking mechanisms, liquidity pools and
                reward structures onto Xonick Pay.
              </li>
            </ul>
          </div>
          {/* Legal and Compliance */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Legal and Compliance</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                <span className="font-semibold text-black">
                  Token Classification:
                </span>
                &nbsp; XNP is classified as a utility token and governance
                token.
              </li>
              <li>
                <span className="font-semibold text-black">
                  Token Classification:
                </span>
                &nbsp; XNP is classified as a utility token and governance
                token.
              </li>
              <li>
                <span className="font-semibold text-black">
                  Regulatory Compliance:
                </span>
                &nbsp; Indian AMLCFT guidelines taken into consideration.
              </li>
            </ul>
          </div>
          {/* General Disclaimer*/}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">General Disclaimer</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                <span className="font-semibold text-black">Non-Commitment</span>
                &nbsp; This document and any other documents published in
                association with this token are for informational purposes only.
                They do not constitute an offer or solicitation to sell shares
                or securities.
              </li>
              <li>
                <span className="font-semibold text-black">No Advice:</span>
                &nbsp; The information set forth herein should not be considered
                financial or legal advice. Please consult your own legal,
                financial, tax, or other professional advisor regarding your
                specific needs.
              </li>
            </ul>
          </div>
          {/* Risks and Uncertainties*/}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Risks and Uncertainties</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                Market Risk: The value of XNP can fluctuate and even diminish to
                zero. Investors may lose their entire investment.
              </li>
              <li>
                Regulatory Risk: The regulatory status of XNP and distributed
                ledger technology is unclear or unsettled in many jurisdictions.
                Changes in regulations or the imposition of new regulations
                could adversely affect the use, transfer, exchange, and value of
                XNP.
              </li>
              <li>
                No Guarantee: There is no guarantee that the functionalities of
                XNP will be delivered or realized. Your purchase of XNP does not
                guarantee you any rights, uses, purpose, attributes,
                functionalities, or features.
              </li>
            </ul>
          </div>
          {/* Technology Risk */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Technology Risk</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                <span className="font-semibold text-black">
                  Technical Uncertainties:
                </span>
                &nbsp; The development of the Xonick Pay platform may encounter
                technical challenges not foreseen today, potentially leading to
                delays or changes to the projected roadmap.
              </li>
              <li>
                <span className="font-semibold text-black">Security Risk:</span>
                &nbsp; There is a risk that the XNP smart contracts may
                unintentionally contain weaknesses or bugs affecting the
                functionality or security of the token.
              </li>
            </ul>
          </div>
          {/* Legal */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">Legal</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                <span className="font-semibold text-black">
                  Compliance and Regulatory:
                </span>
                &nbsp; XNP is intended to be used as a utility and governance
                token on the Xonick Pay platform and is not and will not be
                offered or sold as a security or for speculative purposes. The
                classification of utility tokens is subject to high levels of
                regulatory scrutiny in many jurisdictions, and you are
                responsible for determining whether the purchase of XNP is legal
                in your jurisdiction.
              </li>
              <li>
                <span className="font-semibold text-black">
                  Jurisdictional Restrictions:
                </span>
                &nbsp; The token sale is not open to participants from
                jurisdictions where the purchase of such tokens would be
                considered illegal or unauthorized.
              </li>
            </ul>
          </div>
          {/* Token Purchase Agreement */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">
              Token Purchase Agreement
            </h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                <span className="font-semibold text-black">
                  Acceptance of Terms:
                </span>
                &nbsp; By purchasing XNP, you are agreeing that you have read,
                understood, and accepted all of the terms and conditions
                outlined in the Token Purchase Agreement. You acknowledge that
                you are taking full responsibility for your investment,
                including the risk of losing your entire investment.
              </li>
            </ul>
          </div>
          {/* FAQs */}
          <div className="mt-6 px-6">
            <h2 className="text-2xl font-bold mb-2">FAQs</h2>
            <ul className="text-gray-700 text-lg list-disc pl-11">
              <li>
                Q: What is the minimum purchase amount for the pre-sale?
                <br /> A: The minimum purchase quantity is 10k XNP.
              </li>
              <li>
                Q: When will the tokens be distributed? <br /> A: Initially XNP
                is being launched on Poylgon and will be allocated as a
                Placeholder token. Later on in Q2 when token development and
                integration is completed on Xonick Pay, a TRC-2
              </li>
              <li>
                Q: When will we get the TRC-20 Tokens in our wallets <br /> A:
                Tokens will be distributed within 4 weeks following the
                conclusion of the public sale.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Docs;
