var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpTransportType, HubConnectionBuilder, LogLevel, } from "@microsoft/signalr";
var WalletSignalRService = /** @class */ (function () {
    // Define other event handlers as needed
    // Private constructor to prevent direct instantiation
    function WalletSignalRService() {
        this.token = null;
    }
    // Static method to get the single instance of the class
    WalletSignalRService.getInstance = function () {
        if (!WalletSignalRService.instance) {
            WalletSignalRService.instance = new WalletSignalRService();
        }
        return WalletSignalRService.instance;
    };
    WalletSignalRService.prototype.startConnection = function (url, token) {
        return __awaiter(this, void 0, void 0, function () {
            var options, err_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.token = token;
                        if (!(!this.hubConnection || this.hubConnection.state !== "Connected")) return [3 /*break*/, 4];
                        options = {
                            accessTokenFactory: function () { return _this.token; },
                            transport: HttpTransportType.WebSockets |
                                HttpTransportType.ServerSentEvents |
                                HttpTransportType.LongPolling,
                            logger: LogLevel.Information, // Adjust logging level as needed
                        };
                        this.hubConnection = new HubConnectionBuilder()
                            .withUrl(url, options)
                            .configureLogging(LogLevel.Information)
                            .build();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.hubConnection.start()];
                    case 2:
                        _a.sent();
                        this.registerOnServerEvents();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.handleError("Error while starting connection: " + err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    WalletSignalRService.prototype.registerOnServerEvents = function () {
        var _this = this;
        var _a, _b, _c, _d, _e;
        (_a = this.hubConnection) === null || _a === void 0 ? void 0 : _a.on("ReceiveUserDetails", function (data) {
            var _a;
            (_a = _this.onUserDetailsReceived) === null || _a === void 0 ? void 0 : _a.call(_this, data);
        });
        (_b = this.hubConnection) === null || _b === void 0 ? void 0 : _b.on("ReceiveUserData", function (data) {
            var _a;
            (_a = _this.onUserDataReceived) === null || _a === void 0 ? void 0 : _a.call(_this, data);
        });
        (_c = this.hubConnection) === null || _c === void 0 ? void 0 : _c.on("ErrorMessage", function (errorMessage) {
            var _a;
            (_a = _this.onErrorMessageReceived) === null || _a === void 0 ? void 0 : _a.call(_this, errorMessage);
        });
        (_d = this.hubConnection) === null || _d === void 0 ? void 0 : _d.on("ReceiveWalletDetails", function (data) {
            var _a;
            (_a = _this.onWalletDetailsReceived) === null || _a === void 0 ? void 0 : _a.call(_this, data);
        });
        (_e = this.hubConnection) === null || _e === void 0 ? void 0 : _e.on("TransactionStatus", function (data) {
            var _a;
            (_a = _this.onTransactionUpdate) === null || _a === void 0 ? void 0 : _a.call(_this, data);
        });
    };
    WalletSignalRService.prototype.handleError = function (errorMessage) {
        var _a;
        console.error(errorMessage);
        (_a = this.onErrorMessageReceived) === null || _a === void 0 ? void 0 : _a.call(this, errorMessage);
    };
    WalletSignalRService.prototype.createWithdrawalTransaction = function (url, request) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.hubConnection || this.hubConnection.state !== "Connected")) return [3 /*break*/, 2];
                        console.warn("No connection to the server. Attempting to start a new connection...");
                        return [4 /*yield*/, this.startConnection(url, this.token)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.hubConnection && this.hubConnection.state === "Connected")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.hubConnection
                                .send("CreateWithdrawalTransaction", request)
                                .catch(function (err) { return _this.handleError("Failed to receive messages: " + err); })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.handleError("Failed to receive messages: Connection is not in the 'Connected' state.");
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WalletSignalRService.prototype.createWithdrawalTransactionFeeUpdate = function (url, request) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.hubConnection || this.hubConnection.state !== "Connected")) return [3 /*break*/, 2];
                        console.warn("No connection to the server. Attempting to start a new connection...");
                        return [4 /*yield*/, this.startConnection(url, this.token)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.hubConnection && this.hubConnection.state === "Connected")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.hubConnection
                                .send("UpdateWalletTransactions", request)
                                .catch(function (err) { return _this.handleError("Failed to receive messages: " + err); })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.handleError("Failed to receive messages: Connection is not in the 'Connected' state.");
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WalletSignalRService.prototype.disconnect = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.hubConnection && this.hubConnection.state === "Connected")) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.hubConnection.stop()];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        console.warn("No active wallet connection to disconnect.");
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    WalletSignalRService.prototype.requestWalletDetails = function (url, request) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.hubConnection || this.hubConnection.state !== "Connected")) return [3 /*break*/, 2];
                        console.warn("No connection to the server. Attempting to start a new connection...");
                        return [4 /*yield*/, this.startConnection(url, this.token)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.hubConnection && this.hubConnection.state === "Connected")) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.hubConnection
                                .send("GetUserDetails", request)
                                .catch(function (err) { return _this.handleError("Failed to receive messages: " + err); })];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        this.handleError("Failed to receive messages: Connection is not in the 'Connected' state.");
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    WalletSignalRService.prototype.getWalletDetails = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                (_a = this.hubConnection) === null || _a === void 0 ? void 0 : _a.on("ReceiveUserDetails", function (data) {
                    var _a;
                    (_a = _this.onUserDetailsReceived) === null || _a === void 0 ? void 0 : _a.call(_this, data);
                });
                return [2 /*return*/];
            });
        });
    };
    return WalletSignalRService;
}());
export { WalletSignalRService };
